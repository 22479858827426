"use client";

import { Session } from "next-auth";
import { signOut } from "next-auth/react";
import { SyntheticEvent, useEffect, useState } from "react";
import { Link, useRouter } from "@/navigation";
import { FaEdit, FaPlayCircle, FaShoppingCart } from "react-icons/fa";
import { SafeUserWithAccounts } from "@/types";
import Image from "next/image"
import { useTranslations } from "next-intl";

interface AuthenticatedProps {
    session: Session;
    user: SafeUserWithAccounts;
    firstArea: number | false;
    isLauncher: boolean;
}

export default function Authenticated({ session, user, firstArea, isLauncher }: AuthenticatedProps) {
    const t = useTranslations('Authenticated')

    async function handleSignOut(e: SyntheticEvent) {
        e.preventDefault();

        await signOut({ redirect: false });
    }

    // function getProfilePicture(): string {
    //     if (user?.image)
    //         return user.image;
    //     return "/assets/profile.png";
    // }

    return <div className="flex  flex-col  justify-center m-auto  ">
        <div className="m-auto mt-[9px] mb-[12px]">
            <Image src="/assets/profile.png" alt=""
                width={94} height={94}
                className="rounded"
            />
        </div>
        <div>
            <div className="text-center mb-4">
                <h5 className="text-lg text-[#334155] dark:text-gray-300 font-lato font-bold">#{session.user?.name}</h5>
                <p className="text-[#4F46E5] dark:text-gray-400 text-sm font-lato mt-[4px] font-normal mb-[12px]">
                    {session.user?.email}
                </p>
            </div>
            <div className="flex justify-center mb-4">
                <a href="#" onClick={handleSignOut} className="text-sm text-gray-500 hover:text-gray-700 dark:text-gray-300 mr-2">
                    {t('signOut')}
                </a>
            </div>
            <div className="flex justify-center flex-col mx-auto items-center w-full">
                <Link href="/profile" type="button" className="py-[12px] rounded-[12px] bg-[#4F46E5] mb-[8px] text-white font-lato text-base w-full flex justify-center items-center gap-[4px]">
                    <FaEdit />
                    {t('editProfile')}
                </Link>
                <Link href={!firstArea ? "/play" : `/play/s${firstArea}`} target={isLauncher || firstArea === false ? '' : '_blank'} type="button" className="py-[12px] rounded-[12px] bg-[#4F46E5] mb-[8px] text-white font-lato text-base text-center w-full flex justify-center items-center gap-[4px]">
                    <FaPlayCircle />
                    {t('play')}
                </Link>
                <Link href="/recharge" type="button" className="py-[12px] rounded-[12px] bg-[#4F46E5] mb-[8px] text-white font-lato text-base w-full flex justify-center items-center gap-[4px]">
                    <FaShoppingCart />
                    {t('rechargeNow')}
                </Link>
            </div>
        </div>
    </div>;
}