import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\@next\\third-parties\\dist\\google\\ga.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\@next\\third-parties\\dist\\google\\gtm.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\@next\\third-parties\\dist\\ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Accordion\\Accordion.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Accordion\\AccordionContent.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Accordion\\AccordionPanel.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Accordion\\AccordionTitle.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Banner\\BannerCollapseButton.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Carousel\\Carousel.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\DarkThemeToggle\\DarkThemeToggle.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Datepicker\\Datepicker.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Dropdown\\Dropdown.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Dropdown\\DropdownDivider.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Dropdown\\DropdownHeader.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Dropdown\\DropdownItem.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Floating\\Floating.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Modal\\Modal.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Modal\\ModalBody.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Modal\\ModalFooter.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Modal\\ModalHeader.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Navbar\\Navbar.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Navbar\\NavbarBrand.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Navbar\\NavbarCollapse.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Navbar\\NavbarLink.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Navbar\\NavbarToggle.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Rating\\Rating.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Rating\\RatingStar.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Sidebar\\Sidebar.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Sidebar\\SidebarCollapse.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Sidebar\\SidebarCTA.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Sidebar\\SidebarItem.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Sidebar\\SidebarItemGroup.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Sidebar\\SidebarItems.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Sidebar\\SidebarLogo.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Table\\Table.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Table\\TableBody.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Table\\TableCell.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Table\\TableHead.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Table\\TableHeadCell.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Table\\TableRow.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Tabs\\TabItem.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Tabs\\Tabs.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Timeline\\Timeline.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Timeline\\TimelineBody.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Timeline\\TimelineContent.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Timeline\\TimelineItem.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Timeline\\TimelinePoint.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Timeline\\TimelineTime.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Timeline\\TimelineTitle.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Toast\\Toast.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\components\\Toast\\ToastToggle.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\hooks\\use-theme-mode.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\theme-store\\init\\client.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\flowbite-react\\lib\\esm\\theme-store\\init\\mode.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\next-intl\\dist\\esm\\navigation\\shared\\BaseLink.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\next\\dist\\client\\image-component.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\node_modules\\next\\dist\\client\\script.js");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\src\\app\\[locale]\\(public)\\_components\\Footer\\style.css");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\src\\app\\[locale]\\(public)\\_components\\InstagramBlock\\index.tsx");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\src\\app\\[locale]\\(public)\\_components\\News\\index.tsx");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\src\\app\\[locale]\\(public)\\_components\\User\\index.tsx");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\src\\app\\[locale]\\(public)\\_components\\VideoBackground\\index.tsx");
import(/* webpackMode: "eager" */ "C:\\ddtank36.com.br\\Sites\\play.ddtank36.com.br\\src\\app\\[locale]\\(public)\\styles.css")