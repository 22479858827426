"use client";

import { fetchNews } from "@/actions/fetchNews";
import NewsRow from "./NewsRow";
import { SyntheticEvent, useEffect, useState } from "react";
import { Alert, Spinner } from "flowbite-react";
import { PaginatedNews } from "@/types/PaginatedNews";
import { useTranslations } from "next-intl";

interface NewsBlockProps {
    firstPage: PaginatedNews;
}

export default function NewsBlock({ firstPage }: NewsBlockProps) {
    const t = useTranslations("News");
    const [paginated, setPaginated] = useState<PaginatedNews | "loading">(firstPage);
    const [page, setPage] = useState(0);

    useEffect(() => {
        async function loadData() {
            setPaginated("loading");
            const paginated = await fetchNews(page, 5);

            if (paginated) {
                setPaginated(paginated);
                setPage(paginated.currentPage);
            }
        }

        loadData();
    }, [page]);

    function renderNews() {
        const data = (paginated as PaginatedNews).data;
        if (data.length == 0)
            return <Alert color="warning" className="text-center">
                {t('emptyList')}
            </Alert>;

        return data.map((notice) => (<NewsRow
            key={notice.id}
            news={notice}
            newsLabel={t('type.news')}
            articleLabel={t('type.article')}
        />));
    }

    function renderPagination() {
        const { pages, currentPage } = (paginated as PaginatedNews);

        function changePage(page: number) {
            return function (e: SyntheticEvent) {
                e.preventDefault();

                setPage(page);
            }
        }

        function renderPages() {
            return Array.from({ length: pages }, (_, page) => {
                const selectedClass = page == currentPage ? "bg-[#4338CA] text-white" : "bg-white text-[#334155]";
                return (<li key={page}>
                    <a
                        href="#"
                        onClick={changePage(page)}
                        className={`flex items-center justify-center h-[32px] w-[32px] rounded-[50%] text-[12px] font-medium ${selectedClass}`}
                    >
                        {page + 1}
                    </a>
                </li>);
            });
        }

        return <nav aria-label="Page navigation">
            <ul className="flex items-center gap-[5px] ">
                {renderPages()}
            </ul>
        </nav>;
    }

    function renderContent() {
        if (paginated == "loading") {
            return <div className="text-center p-[24px] pb-[20px] pt-[0]">
                <Spinner />
            </div>
        }

        return <div className="p-[24px] pb-[20px] pt-[0]">
            <div>{renderNews()}</div>
            {/* pagination */}
            <div className="flex justify-center">
                {renderPagination()}
            </div>
        </div>;
    }

    return <>
        <div className="py-[13px] bg-[#4F46E5] dark:bg-[#27237a] text-center  rounded-tl-[24px] mb-[28px] rounded-tr-[24px] ">
            <h3 className="text-white  text-2xl  font-bold leading-[32px] ">
                {t('blockTitle')}
            </h3>
        </div>

        {/* news  */}
        {renderContent()}
    </>
}