import { z } from "zod";

export const LoginValidator = z.object({
    email: z
        .string({ required_error: "email.required" })
        .email({ message: "email.invalid" }),
    password: z
        .string({ required_error: "password.required" })
        .min(8, { message: "password.minLength" })
        .max(16, { message: "password.maxLength" })
});