"use client";

import Image from "next/image";
import { useTranslations } from "next-intl";
import { ChangeEvent, FormEvent, SyntheticEvent, useState } from "react";
import { SignInResponse, signIn } from "next-auth/react";
import { usePathname, useSearchParams } from "next/navigation";
import { Alert } from "flowbite-react";
import { LoginValidator } from "@/validators/login";
import { TextInput } from "@/components";
import { Link } from "@/navigation";

export default function Unauthenticated() {
  const t = useTranslations("Auth");
  const pathname = usePathname();
  const query = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [submited, setIsSubmited] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsSubmited(true);

    setIsLoading(true);
    const validate = await LoginValidator.safeParseAsync({
      email,
      password,
    });

    if (validate.success) {
      const result = await signIn("credentials", {
        redirect: false,
        callbackUrl: pathname,
        username: email,
        password,
      });

      handleResult(result);
    } else {
      setErrors(
        validate.error.errors.reduce(
          (acc, cr) => ({ ...acc, [cr.path[0]]: t(`errors.${cr.message}`) }),
          {}
        )
      );
      setIsLoading(false);
    }
  }

  function handleResult(result: SignInResponse | undefined) {
    setIsLoading(false);
    if (!result?.ok) alert(t('errors.credentials'));
  }

  async function handleGoogleSignIn(event: SyntheticEvent) {
    event.preventDefault();

    setIsLoading(true);
    await signIn("google");
  }

  async function handleFacebookSignIn(event: SyntheticEvent) {
    event.preventDefault();

    setIsLoading(true);
    await signIn("facebook");
  }

  function renderAlert() {
    const error = query.get("error");
    if (error) {
      switch (error) {
        case "AUTH_UEX":
          return (
            <Alert color="failure" className="mb=[12px]">
              {t("errors.email.alreadyUse")}
            </Alert>
          );
      }
      return null;
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {renderAlert()}
      {isLoading && (
        <div className="rounded-[24px] absolute inset-0 flex justify-center items-center z-10 bg-gray-500 opacity-50"></div>
      )}
      <TextInput
        type="email"
        name="userEmail"
        placeholder={t("fields.login")}
        value={email}
        onChange={handleEmailChange}
        disabled={isLoading}
        error={errors.email}
        success={!errors.email && submited}
      />
      <TextInput
        type="password"
        name="userPassword"
        placeholder={t("fields.password")}
        value={password}
        onChange={handlePasswordChange}
        disabled={isLoading}
        error={errors.password}
        success={!errors.password && submited}
      />

      <button
        type="submit"
        className={`mt-[20px] mb-[24px] bg-[#4F46E5] dark:bg-[#6B46E5] rounded-[12px] w-full py-[12px] text-white text-base font-semibold font-lato ${
          isLoading ? "opacity-50 cursor-not-allowed" : ""
        }`}
        disabled={isLoading}
      >
        {t('submit')}
      </button>
      {/* <div className="text-center">
        <p className="text-[#9CA3AF] dark:text-[#6B7280] tex-sm font-medium mb-[20px]">
          {t('or')}
        </p>
      </div>
      <div className="mb-[20px]">
        <button
          onClick={handleFacebookSignIn}
          className={`py-[12px] text-base dark:text-white font-medium bg-[#2563EB] dark:bg-[#3B82F6] rounded-[12px] w-full flex items-center justify-center gap-[10px] ${
            isLoading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isLoading}
        >
          <Image
            src="/assets/facebook.png"
            alt="facebook"
            width={20}
            height={20}
          />
          <span className="text-base font-medium text-white leading-[24px]">
            {t('facebook')}
          </span>
        </button>
        <button
          onClick={handleGoogleSignIn}
          className={`py-[12px] text-base font-medium bg-[#DC2626] dark:bg-[#ad1919] rounded-[12px] w-full flex items-center justify-center gap-[10px] mt-[12px] ${
            isLoading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isLoading}
        >
          <Image
            src="/assets/google.png"
            alt="facebook"
            width={20}
            height={20}
          />
          <span className="text-base font-medium text-white leading-[24px]">
            {t('google')}
          </span>
        </button>
      </div> */}

      <div className="flex justify-between gap-2">
        <Link
          href="/profile/change-password"
          className="text-[#64748B] dark:text-gray-400 text-sm font-semibold"
        >
          {t('forgetPassword')}
        </Link>

        <Link
          href="/register"
          type="button"
          className="text-[#4F46E5] dark:text-[#cacaca] text-sm font-semibold "
        >
          {t('createAccount')}
        </Link>
      </div>
    </form>
  );
}
