"use client";

export default function VideoBackground() {
  const isDev = process && process.env.NODE_ENV === "development";
  if (isDev) return <></>;

  return (
    <>
      <div className="h-[0]">
        <video
          className="w-full"
          autoPlay
          loop
          muted
          style={{ backgroundImage: "url('/assets/background.png')" }}
        >
          <source src="/assets/V.3.mp4" type="video/mp4" />
        </video>
      </div>
    </>
  );
}
