const { env } = process;

const config = {
  websiteUrl: env.WEBSITE_URL ?? "",
  database: {
    website: env.DB_WEBSITE_URL,
    tank: env.DB_TANK_URL,
    tank36: env.DB_TANK36_URL,
    baseTank36: env.DB_TANK36_BASEURL,
  },
  block: {
    characters: env.SERVER_BLOCK_CHARACTERS,
    recharge: env.SERVER_BLOCK_RECHARGE
  },
  i18n: {
    locales: ["pt"], //["en", "pt"],
    defaultLocale: env.I18N_DEFAULT_LOCALE ?? "en",
    timeZone: env.I18N_TIMEZONE ?? "America/Sao_Paulo",
  },
  analytics: {
    googleAnalyticsId: env.GOOGLE_ANALYTICS_ID,
    googleTagManagerId: env.GOOGLE_TAG_MANAGER_ID
  },
  tinyMCE: {
    apiKey: env.TINYMCE_KEY,
  },
  auth: {
    nextSecretKey: env.NEXTAUTH_SECRET,
    cryptSaltRound: parseInt(env.CRYPT_SALT ?? "10"),
    google: {
      clientId: env.GOOGLE_CLIENT_ID,
      secretKey: env.GOOGLE_SECRET_KEY,
    },
    facebook: {
      clientId: env.FACEBOOK_APP_ID,
      secretKey: env.FACEBOOK_SECRET_KEY,
    },
  },
  social: {
    instagram: {
      profileUrl: env.INSTAGRAM_PROFILE,
    },
    facebook: {
      profileUrl: env.FACEBOOK_PROFILE,
    },
    discord: {
      profileUrl: env.DISCORD_PROFILE,
    },
    youtube: {
      profileUrl: env.YOUTUBE_PROFILE,
    },
  },
  game: {
    pageUrl: env.GAME_URL,
  },
  downloads: {
    launcher: env.LAUNCHER_URL,
    mobile: env.MOBILE_URL,
    mac: env.MAC_URL,
    ios: env.IOS_URL,
    linux: env.LINUX_URL
  },
  mail: {
    host: env.MAIL_HOST ?? "",
    port: parseInt(env.MAIL_PORT ?? "0"),
    secure: env.MAIL_SECURE?.toLowerCase() == "true",
    username: env.MAIL_USERNAME,
    password: env.MAIL_PASSWORD,
    sender: env.MAIL_ADDRESS ?? "",
    service: env.MAIL_SERVICE,
  },
  privacy: {
    termsOfService: env.TOS_URL ?? "",
    privacyPolicy: env.PRIVACY_URL ?? "",
  },
  twilio: {
    accountSid: env.TWILIO_ACCOUNT_SID ?? "",
    authToken: env.TWILIO_AUTH_TOKEN ?? "",
    phoneNumber: env.TWILIO_PHONE_NUMBER ?? "",
  },
  payment: {
    chargeKey: env.PAYMENT_CHARGE_KEY ?? "",
    efi: {
      clientId: env.PAYMENT_EFI_CLIENTID ?? "",
      clientSecret: env.PAYMENT_EFI_CLIENTSECRET ?? "",
      sandbox: env.PAYMENT_EFI_SANDBOX?.toLowerCase() == "true",
      certificate: env.PAYMENT_EFI_CERTIFICATE ?? "",
      validateMtls: env.PAYMENT_EFI_VALIDATE_MTLS?.toLowerCase() == "true",
      pixKey: env.PAYMENT_EFI_PIXKEY ?? "",
      proxyLink: env.PAYMENT_EFI_PROXY ?? ""
    },
    picpay: {
      token: env.PAYMENT_PICPAY_TOKEN ?? "",
      seller: env.PAYMENT_PICPAY_SELLER ?? ""
    },
  },
  play: {
    loginKey: env.PLAY_LOGIN_KEY ?? "",
  },
};

export function useConfig(): typeof config {
  return config;
}

export { config as InternalConfig };
