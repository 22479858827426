import { Tooltip } from 'flowbite-react';
import React from 'react';
import { HiOutlineCheckCircle, HiOutlineExclamationCircle } from "react-icons/hi"

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    error?: string;
    success?: boolean;
}

export default function TextInput({ className, success, error, ...rest }: TextInputProps) {
    function renderIcon() {
        if (error) {
            return <div className="absolute top-1/2 right-3 transform -translate-y-1/2 mr-1">
                <Tooltip content={error} className='min-w-[100px]'>
                    <HiOutlineExclamationCircle className="h-6 w-6 text-red-500 cursor-pointer" />
                </Tooltip>
            </div>
        }

        if (success) {
            return <div className="absolute top-1/2 right-3 transform -translate-y-1/2 mr-1">
                <HiOutlineCheckCircle className="h-6 w-6 text-green-500 cursor-pointer" />
            </div>
        }

        return null;
    }

    return (
        <div className="relative px-[12px] py-[8px] w-full">
            <input
                {...rest}
                className={`w-full rounded-[4px] bg-[#fff] dark:bg-gray-700 dark:text-white leading-tight focus:outline-none focus:shadow-outline ${error ? 'border-red-500' : ''} ${success ? 'border-green-500' : ''} ${className}`}
            />
            {error && renderIcon()}
        </div>
    );
};