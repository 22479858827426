"use client";

import { InstagramEmbed } from "react-social-media-embed";

interface InstagramBlockProps {
  profileUrl?: string;
}

export default function InstagramBlock({ profileUrl }: InstagramBlockProps) {
  if (process.env.NODE_ENV == "development")
    return <h1 className="dark:text-white">Disabled on development (pc slow)</h1>;

  /* Caso seja necessario voltar, o bloco anterior abaixo
  <div className="p-[24px]  relative login_wrapper bg-login-frame bg-[#E0E7FF] shadow-login rounded-[24px]">
                <div className="absolute left-[-110px]">
                  <Image
                    className="img-move"
                    src="/assets/postdolimg.png"
                    alt=""
                    width={141}
                    height={122}
                  />
                </div>
 
                <div className="flex justify-between items-center px-[8px] py-[10px] rounded-tl-[16px] rounded-tr-[16px] rounded-br-0 rounded-bl-0 bg-white">
                  <div className="flex  items-center gap-[6px] ">
                    <Image
                      src="/assets/newsProfile.png"
                      alt="newsProfile"
                      width={34}
                      height={34}
                    />
                    <div className="">
                      <h5 className="text-[#374151] text-[12px]  font-bold  leading-[16px] ">
                        Ruffles
                      </h5>
                      <p className=" text-[12px] font-normal text-[#64748] mt-[2px] ">
                        Sponsored
                      </p>
                    </div>
                  </div>
                  <div>
                    <Image
                      src="/assets/dots.png"
                      alt="dots"
                      width={16}
                      height={16}
                    />
                  </div>
                </div>
                <div className="w-full relative">
                  <Image
                    className="w-full h-[306px]"
                    src="/assets/post.png"
                    alt="post"
                    width={283}
                    height={280}
                  />
 
                  <div className="absolute bottom-[6px] left-[12px] ">
                    <Image
                      src="/assets/Tags.svg"
                      alt="Tags"
                      width={19}
                      height={18}
                    />
                  </div>
                </div>
                <div className="py-[12px] px-[8px]  rounded-bl-[16px] rounded-br-[16px] bg-white flex justify-between items-center ">
                  <div className="flex items-center gap-[8px]">
                    <Image
                      src="/assets/love.png"
                      alt="love"
                      width={16}
                      height={17}
                    />
                    <Image
                      src="/assets/chat.png"
                      alt="chat"
                      width={16}
                      height={17}
                    />
                    <Image
                      src="/assets/send.png"
                      alt="send"
                      width={16}
                      height={17}
                    />
                  </div>
                  <div>
                    <Image
                      src="/assets/bookmark.png"
                      alt="bookmark"
                      width={16}
                      height={16}
                    />
                  </div>
                </div>
              </div>
  */

  return <div>{profileUrl && <InstagramEmbed
    id="embed_block_inst"
    url={profileUrl}
    placeholderSpinner
  />}</div>
}