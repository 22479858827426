import { createSharedPathnamesNavigation } from "next-intl/navigation";
import { InternalConfig } from "./config";

const {
  i18n: { locales },
} = InternalConfig;

export { locales };
export const localePrefix = "always"; // Default

export const { Link, redirect, usePathname, useRouter } =
  createSharedPathnamesNavigation({ locales, localePrefix });
