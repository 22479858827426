import { Link, useRouter } from "@/navigation";
import { Website } from "Prisma";
import { useFormatter } from "next-intl";

interface NewsRowProps {
    news: Website.News;
    newsLabel: string;
    articleLabel: string;
};

export default function NewsRow({ news, newsLabel, articleLabel }: NewsRowProps) {
    const format = useFormatter();
    const formattedDate = format.dateTime(news.postDate, {
        dateStyle: "short",
    })

    function renderType(type: number) {
        if (type == 0) {
            return <span className="text-white text-sm font-lato bg-black rounded-[13px] px-[12px] py-[3px] mb-[8px] ">
                {newsLabel}
            </span>
        } else {
            return <span className="text-white text-sm font-lato bg-[#16A34A] rounded-[13px] px-[12px] py-[3px] mb-[8px] ">
                {articleLabel}
            </span>
        }
    }

    return <Link href={`/news/${news.slug}`}
        className="flex justify-between items-center p-[12px] h-[50px] bg-white dark:bg-gray-800 rounded-[8px] mb-[12px] cursor-pointer"
    >
        <div className="flex items-center  gap-[16px] cursor-pointer">
            {renderType(news.type)}
            <p className="text-[#334155] dark:text-white text-base font-medium leading-[24px] ">
                {news.title}
            </p>
        </div>
        <div className="text-end">
            <p className="dark:text-gray-200 text-sm font-normal leading-[20px] text-[#4F46E5] text-right">
                {formattedDate}
            </p>
        </div>
    </Link>
}