"use client";

import { getUserByEmail } from "@/actions/getUserByEmail";
import Authenticated from "./Authenticated";
import Unauthenticated from "./Unauthenticated";
import { Spinner } from "flowbite-react";
import { Session } from "next-auth";
import { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { SafeUserWithAccounts } from "@/types";

interface UserBlockProps {
  firstArea: number | false;
  isLauncher: boolean;
}

export default function UserBlock({ firstArea, isLauncher }: UserBlockProps) {
  const { status, data } = useSession();
  const [session, setSession] = useState<Session | undefined>();
  const [user, setUser] = useState<SafeUserWithAccounts | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadUser() {
      const email = data?.user?.email;
      if (!email) return;

      const loadedUser = await getUserByEmail(email);
      if (loadedUser) setUser(loadedUser);

      setIsLoading(false);
    }

    setIsLoading(true);
    if (status == "authenticated") {
      setSession(data);
      loadUser();
    } else if (status === "unauthenticated") {
      setSession(undefined);
      setUser(undefined);
      setIsLoading(false);
    }
  }, [status, data]);

  if (isLoading)
    return (
      <div className="flex justify-center gap-4 items-center">
        <Spinner size="xl" />
        <span className="dark:text-white">Fetching session...</span>
      </div>
    );

  if (session && user) {
    return (
      <Authenticated
        session={session}
        user={user}
        firstArea={firstArea}
        isLauncher={isLauncher}
      />
    );
  }

  return <Unauthenticated />;
}
